// Third party
import React from "react";
import Link from "gatsby-link";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layout";
import UspList from "../components/UspList";
import ContentList from "../components/ContentList";
import Section from "../components/Section";
import Container from "../components/Container";
import Hero from "../components/Hero";
import Seo from "../components/seo";

const IndexPage = ({ data }) => (
  <div>
    <Layout>
      <Seo
        description={`Hormoon Yoga heeft effect op je hele lichaam, in het bijzonder op schildklier, hypofyse en eierstokken. De serie leidt tot snelle resultaten bij regelmatige beoefening.`}
      />
      <Hero>
        <Container centered>
          <div className="grid">
            <div className="grid__col grid__col--3-of-5 grid__col--centered">
              <h1>{data.site.siteMetadata.title}</h1>
              <p>
                Hormoon Yoga heeft effect op je hele lichaam, in het bijzonder
                op schildklier, hypofyse en eierstokken. Daardoor kan een
                verstoorde hormoonspiegel weer in balans komen. De serie is
                dynamisch met mooie rustige momenten en leidt tot snelle
                resultaten bij regelmatige beoefening.
              </p>
            </div>
          </div>
        </Container>
      </Hero>
      <Section>
        <Container centered>
          <h2>Voor wie is Hormoon Yoga</h2>
          <p>
            Hormoon yoga is aan te raden voor iedere vrouw. Jong en oud. Hormoon
            Yoga is specifiek bedoeld voor vrouwen met hormonale klachten. Maar
            ook als je geen klachten hebt, is het gewoon een hele fijne
            yogaserie.
          </p>
        </Container>
        <Container>
          <UspList items={data.allContentfulUsp.edges} />
        </Container>
      </Section>
      <Section inverted>
        <Container centered>
          <h2>Begeleid door een professionele Yoga therapeut</h2>
          <p>
            Hormoon Yoga volgens de oorspronkelijke methode met empirisch
            bewezen effectiviteit, ontwikkeld door Dinah Rodrigues.
          </p>
        </Container>
        <Container>
          <ContentList
            items={[{ node: data.allContentfulFeatured.edges[0].node.teacher }]}
            parent="teachers"
          />
        </Container>
      </Section>
      <Section>
        <Container centered>
          <h2>Testimonials</h2>
          <p>Wat vindt de klant?</p>
        </Container>
        <Container>
          <ContentList
            items={[
              { node: data.allContentfulFeatured.edges[0].node.testimonial },
            ]}
            parent="testimonials"
          />
        </Container>
      </Section>
    </Layout>
  </div>
);

export default IndexPage;

export const indexQuery = graphql`
         query IndexQuery {
           site {
             siteMetadata {
               title
             }
           }
           allContentfulFeatured(filter: { node_locale: { eq: "nl" } }) {
             edges {
               node {
                 teacher {
                   id
                   title
                   body {
                     childMarkdownRemark {
                       excerpt
                       html
                     }
                   }
                   slug
                   avatar {
                     fixed(width: 448, height: 448) {
                       src
                     }
                   }
                 }
                 testimonial {
                   id
                   slug
                   title
                   avatar {
                     fixed(width: 100, height: 100) {
                       src
                     }
                   }
                   body {
                     childMarkdownRemark {
                       excerpt
                       html
                     }
                   }
                 }
               }
             }
           }
           allContentfulUsp(filter: { node_locale: { eq: "nl" } }) {
             edges {
               node {
                 id
                 title
                 body {
                   childMarkdownRemark {
                     html
                   }
                 }
               }
             }
           }
         }
       `;
