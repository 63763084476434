import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// CSS Grid uses toast-grid
// https://github.com/daneden/toast

const UspItem = props => {
  const { id, title } = props;
  const Heading = styled.h3`
    font-size: 1.333rem;
  `;
  const body = {
    __html: props.body.childMarkdownRemark.html,
  };
  return (
    <div key={id} className="grid__col--1-of-3 grid__col">
      <Heading>{title}</Heading>
      <div dangerouslySetInnerHTML={body} />
    </div>
  );
};

export default UspItem;

UspItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.object,
};
