import React , {Component} from 'react'
import PropTypes from 'prop-types'
import UspItem from './UspItem'

// CSS Grid uses toast-grid

class UspList extends Component {
  render () {
    return (
      <div className="grid">
        { this.props.items.map(item => <UspItem key={item.node.id} {...item.node} /> ) }
      </div>
    )
  }
}

export default UspList;

UspList.propTypes = {
  items: PropTypes.array
}